.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-columns {
  display: flex;
}
.App-columns .column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 30px;
}
.column-title {
  font-size: 2em;
  font-weight: bold;
}

.products-list {
  list-style: none;
}

.products-list li {
  align-items: center;
  background-color: #eaeaea;
  border-radius: 5px;
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
  margin: 15px;
  padding: 5px;
}

.product-list__item .item-details {
  align-items: center;
  display: flex;
  width: 60%;
}

.product-list__item .item-name {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.product-list__item .item-icon {
  display: flex;
  font-size: 1.5em;
}

.product-list__item .item-code {
  font-size: 0.75em;
  color: #777;
  margin-bottom: 0.5em;
}

.product-list__item .item-price {
  color: rgb(27, 97, 62);
}

.product-list__item .item-add {
  background-color: rgb(34, 16, 151);
  border-radius: 5px;
  color: #fff;
  float: right;
  padding: 7px;
}
