.cart-total {
  border-top: 3px solid black;
}

.cart-total-dollars {
  float: right;
  color: rgb(27, 97, 62);
}

.product-list__cupon .item-name {
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 0.025em;
  text-align: end;
  text-transform: uppercase;
  width: 75%;
}

.product-list__cupon .item-price {
  color: rgb(102, 4, 45);
}
